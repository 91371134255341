import axios from "axios";
import store from "@/store";
import router from "@/router/";

export default () => {
  const token = store.state.auth.token;
  const Http = axios.create({
    baseURL: process.env.VUE_APP_STUDENT_API,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      api_token: `${token}`, // used to authenticate purpose defined in student app api
    },
  });
  Http.interceptors.response.use(
    (response) => {
      // console.log(response);
      if (response.data.status == "AUTH_ERROR") {
        store.dispatch("auth/logout");
        router.push({ name: "Login" }).catch((err) => err);
      }
      return response;
    },
    (error) => {
      // console.error(error);
      if (error.response) {
        if (error.response.data.status == 401) {
          store.dispatch("auth/logout");
          router.push({ name: "Login" }).catch((err) => err);
        }
        if (error.response.data.status == "AUTH_ERROR") {
          store.dispatch("auth/logout");
          router.push({ name: "Login" }).catch((err) => err);
        }
      }
      return Promise.reject(error);
    }
  );
  return Http;
};
