<template>
  <div class="text-center">
    <img class="state-img" src="../assets/no-class-state.svg" alt="" />
  </div>
  <p class="loader mx-auto d-block err-message">{{ messageText }}</p>
</template>

<script>
export default {
  name: "EmptyList",
  props: {
    messageText: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.loading,
p.err-message {
  text-align: center;
  color: #baa898;
  font-size: 20px;
  font-weight: 500;
}
.state-img {
  width: 150px;
}
</style>
