const auth = {
  namespaced: true,
  state: {
    isAuthenticating: false,
    token: "",
    user: {},
    status: "",
    nextUrl: "",
    expirationTime: "",
  },
  mutations: {
    AUTH_REQUEST: (state, status) => {
      state.isAuthenticating = status;
    },
    AUTH_SUCCESS: (state, data) => {
      state.status = "loggedIn";
      state.token = data.token;
      state.user = JSON.stringify(data.user);
    },
    AUTH_ERROR: (state) => {
      state.status = "loggedOut";
      state.status = "error";
      state.expirationTime = "";
    },
    UPDATE_USER: (state, user) => {
      state.user = user;
    },
    LOGOUT: (state) => {
      state.status = "";
      state.token = "";
      state.status = "loggedOut";
      state.user = {};
      state.nextUrl = "";
      state.expirationTime = "";
    },
    UPDATE_NEXT_URL: (state, url) => {
      state.nextUrl = url;
    },
    ADD_EXPIRATION_TIME: (state, time) => {
      state.expirationTime = time;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
    expirationTime: (state) => state.expirationTime,
  },
  actions: {
    authRequest: ({ commit }, status) => {
      commit("AUTH_REQUEST", status);
    },
    authSuccess: ({ commit }, data) => {
      commit("AUTH_SUCCESS", data);
    },
    authError: ({ commit }) => {
      commit("AUTH_ERROR");
    },
    updateUser: ({ commit }, user) => {
      commit("UPDATE_USER", user);
    },
    logout: ({ commit }) => {
      commit("LOGOUT");
    },
    updateNextUrl: ({ commit }, url) => {
      commit("UPDATE_NEXT_URL", url);
    },
    SetExpirationTime: ({ commit }, time) => {
      commit("ADD_EXPIRATION_TIME", time);
    },
  },
};

export default auth;
