<template>
  <Head>
    <title v-if="content && content.title !== null">{{ content.title }}</title>
    <title v-else>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <ComingSoon v-if="isComingSoon" />
  <div v-else class="home">
    <div class="container-fluid banner-wrapper">
      <img v-if="banner" :src="banner.image" class="banner-img" />
      <!-- <img src="../assets/JzzSwzRMfjfBZosmLKO7UX8iyqVH8uKgG9966r23.png" class="banner-img"/> -->
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-xl-4 col-lg-5 col-md-5 col-sm-8">
            <div class="banner-form">
              <h3 v-if="content && content.pos_form_title !== null">
                {{ content.pos_form_title }}
              </h3>
              <form class="row g-0" @submit.prevent="saveCounselling">
                <div class="col-md-12 field-carrier">
                  <input
                    type="text"
                    class="form-control"
                    v-model="name"
                    required="required"
                  />
                  <label>Student Name </label>
                  <div v-if="errors && errors.name" class="error-msg">
                    {{ errors.name[0] }}
                  </div>
                </div>
                <div class="col-md-12 field-carrier">
                  <input
                    type="text"
                    class="form-control"
                    v-model="your_name"
                    required="required"
                  />
                  <label>Parent Name </label>
                  <div v-if="errors && errors.your_name" class="error-msg">
                    {{ errors.your_name[0] }}
                  </div>
                </div>
                <div class="col-md-12">
                  <Multiselect
                    v-model="standardOptions.value"
                    v-bind="standardOptions"
                  />
                  <div v-if="errors && errors.standard_id" class="error-msg">
                    {{ errors.standard_id[0] }}
                  </div>
                </div>
                <div class="col-md-12 field-carrier">
                  <input
                    type="text"
                    class="form-control"
                    v-model="email"
                    required="required"
                  />
                  <label>Email ID </label>
                  <div v-if="errors && errors.email" class="error-msg">
                    {{ errors.email[0] }}
                  </div>
                </div>
                <div class="col-md-12 field-carrier">
                  <input
                    type="text"
                    class="form-control"
                    v-model="phone"
                    required="required"
                  />
                  <label>Mobile Number </label>
                  <div v-if="errors && errors.phone_number" class="error-msg">
                    {{ errors.phone_number[0] }}
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <button
                    type="submit"
                    :disabled="isSubmitting == true"
                    class="btn cta-primary"
                  >
                    Schedule a call back
                  </button>
                </div>
              </form>
              <!-- <div class="download-div">
                <span>
                  Download app
                </span>
                <a href="https://play.google.com/store/apps/details?id=com.vblive.vblive_student" target="_blank">
                  <img src="../assets/Google-Play.png" class="store-icon"/>
                </a>
                <a href="https://apps.apple.com/in/app/vblive-your-online-tutor/id1591906505" target="_blank">
                  <img src="../assets/App-Store.png" class="store-icon"/>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <Achievement v-if="achievements" :achievements="achievements" />

    <div class="choose-wrapper">
      <div class="container">
        <div class="row choose-row">
          <div class="col-md-4">
            <div class="mb-3 header-box">
              <h1 class="highlite">Immersive learning</h1>
              <h1>That makes all the difference.</h1>
            </div>
            <div v-if="standardLoader" class="loading">Loading...</div>
            <div v-else>
              <ul
                v-if="activeStandards.length > 0"
                class="nav nav-pills mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li
                  v-for="(standard, standardIndex) in activeStandards"
                  :key="standard.id"
                  class="nav-item"
                  role="presentation"
                >
                  <button
                    class="nav-link"
                    :class="standardIndex === 0 ? 'active' : ''"
                    @click="fetchSelectedStandardPrograms(standard)"
                    :id="'pills-class' + standard.id + '-tab'"
                    data-bs-toggle="pill"
                    :data-bs-target="'#pills-class' + standard.id"
                    type="button"
                    role="tab"
                    :aria-controls="'pills-class' + standard.id"
                    aria-selected="true"
                  >
                    {{ standard.standard_title }}
                  </button>
                </li>
              </ul>
              <EmptyList v-else messageText="No active standards found!" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="tab-content" id="pills-tabContent">
              <div
                v-for="(standard, standardIndex) in activeStandards"
                :key="standard.id"
                class="tab-pane fade"
                :class="standardIndex === 0 ? 'show active' : ''"
                :id="'pills-class' + standard.id"
                role="tabpanel"
                :aria-labelledby="'pills-class' + standard.id + '-tab'"
              >
                <!-- <div v-if="programLoader" class="loading">Loading...</div> -->
                <div v-if="programLoader" class="loading">
                  <CircularLoader />
                </div>
                <div v-else>
                  <div v-if="featuredPrograms.length > 0" class="row">
                    <CourseCard
                      v-for="featuredProgram in featuredPrograms"
                      :key="featuredProgram.id"
                      :program="featuredProgram"
                      :toggleImage="false"
                      :toggleBanner="true"
                      :togglePrice="true"
                      :toggleFilteredWeeks="true"
                      :colValue="colval"
                      routeLink="CourseDetails"
                    />
                  </div>
                  <EmptyList v-else messageText="No featured courses found!" />
                </div>
              </div>
            </div>
            <div class="text-end">
              <router-link
                :to="{
                  name: 'ExploreCourses',
                  query: { standard: standardText },
                }"
                class="btn cta-clear"
                >See More <img src="../assets/arrow.svg" alt="" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goal-wrapper">
      <div class="container">
        <p class="highlight-text">lets grow together</p>
        <h1>How we set our goal to be the best</h1>
        <div class="row bg-row align-items-center">
          <div class="col-md-4 col-left">
            <div class="goal-card">
              <div class="_head">
                <h5>Providing experienced faculties</h5>
              </div>
              <div class="_body">
                <p>
                  The right guidance is one accompanied by Professionals and
                  that’s exactly what we offer at VB Live
                </p>
                <a href="#">
                  <!-- <img src="../assets/faculty-cta.svg" alt="" /> -->
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-center">
            <div class="goal-card">
              <div class="_head">
                <h5>Personalized space for each</h5>
              </div>
              <div class="_body">
                <p>
                  We do care students emotions. Our approach allows children to
                  learn through discovery, self realization, sensory engagement
                  and more through individual focus
                </p>
                <img src="../assets/goal-cardImg.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-md-4 col-right">
            <div class="goal-card">
              <div class="_head">
                <h5>Well structured online library</h5>
              </div>
              <div class="_body">
                <p>
                  A collection curated by experts to satisfy every student's
                  needs, novels to enlighten souls, study material and research
                  notes for the intrigued minds
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-wrapper">
      <div class="container">
        <div class="row stat-row align-items-center">
          <div class="col-md-6">
            <h1 v-if="studentStatistics">
              {{ studentStatistics.title }}
            </h1>
            <p
              v-if="studentStatistics"
              class="sub-tag"
              v-html="studentStatistics.short_description"
            ></p>
          </div>
          <div class="col-md-6">
            <div class="row stat-digit">
              <div class="col-md-6 stats">
                <div class="stat-item">
                  <img src="../assets/rank-icon.svg" alt="" />
                  <h2 v-if="studentStatistics">
                    {{ studentStatistics.statistics_one_value }}
                  </h2>
                  <p v-if="studentStatistics">
                    {{ studentStatistics.statistics_one }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 stats">
                <div class="stat-item">
                  <img src="../assets/trophy-icon.svg" alt="" />
                  <h2 v-if="studentStatistics">
                    {{ studentStatistics.statistics_two_value }}
                  </h2>
                  <p v-if="studentStatistics">
                    {{ studentStatistics.statistics_two }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 stats">
                <div class="stat-item">
                  <img src="../assets/student-icon.svg" alt="" />
                  <h2 v-if="studentStatistics">
                    {{ studentStatistics.statistics_three_value }}
                  </h2>
                  <p v-if="studentStatistics">
                    {{ studentStatistics.statistics_three }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 stats">
                <div class="stat-item">
                  <img src="../assets/scholar-icon.svg" alt="" />
                  <h2 v-if="studentStatistics">
                    {{ studentStatistics.statistics_four_value }}
                  </h2>
                  <p v-if="studentStatistics">
                    {{ studentStatistics.statistics_four }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="experience-wrapper">
          <Testimonial
            v-if="sortedTestimonials.length > 0"
            :sortedTestimonials="sortedTestimonials"
          />
        </div>
      </div>
    </div>
    <div class="events-wrapper">
      <div class="container">
        <div class="text-center">
          <h1>Events</h1>
        </div>
        <div v-if="eventLoader" class="loading">Loading...</div>
        <div v-else>
          <div class="row" v-if="featuredEvents.length > 0">
            <EventCard
              v-for="featuredEvent in featuredEvents"
              :key="featuredEvent.id"
              :event="featuredEvent"
            />
          </div>
          <EmptyList v-else messageText="No featured events found!" />
          <div class="text-center">
            <router-link :to="{ name: 'ExploreEvents' }" class="btn cta-primary"
              >EXPLORE EVENTS
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="blogLoading" class="loading">Loading...</div>
    <div v-else class="career-wrapper">
      <div class="container text-center">
        <h1 v-if="content && content.blog_content !== null">blogs</h1>
        <p v-if="content && content.blog_content !== null">
          {{ content.blog_content }}
        </p>
        <div class="cta-wrap"></div>
        <div class="row">
          <div
            class="col-md-3"
            v-for="featuredBlog in featuredBlogs"
            :key="featuredBlog.id"
          >
            <router-link
              :to="{
                name: 'BlogSingle',
                params: { slug: featuredBlog.slug },
              }"
              class="blog-card"
            >
              <div class="img-wrap">
                <img
                  v-if="featuredBlog.image != null"
                  :src="featuredBlog.image"
                  alt=""
                />
                <img v-else src="../assets/thumb.png" alt="" />
              </div>
              <div class="blog-body">
                <h5>{{ featuredBlog.title }}</h5>
                <div class="info-row">
                  <p class="credit">by {{ featuredBlog.author }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="blogCount > 4" lass="cta-wrap">
          <router-link :to="{ name: 'BlogListing' }" class="btn cta-primary"
            >Show More</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import EventCard from "@/components/EventCard.vue";
import EmptyList from "@/components/EmptyList.vue";
import Testimonial from "@/components/Testimonial.vue";
import CourseService from "@/services/CourseService";
import EventService from "@/services/EventService";
import CommonService from "@/services/CommonService";
import CircularLoader from "@/components/loaders/CircularLoader";
import ComingSoon from "@/components/ComingSoon.vue";
import Achievement from "@/components/Achievement.vue";
import Multiselect from "@vueform/multiselect";
import { Head } from "@vueuse/head";

export default {
  name: "Home",
  components: {
    Head,
    CourseCard,
    EventCard,
    EmptyList,
    Testimonial,
    CircularLoader,
    ComingSoon,
    Achievement,
    Multiselect,
  },
  data() {
    return {
      content: null,
      standardLoader: true,
      standardId: "",
      standardText: "",
      eventLoader: true,
      programLoader: true,
      banner: null,
      activeStandards: [],
      featuredPrograms: [],
      featuredEvents: [],
      studentStatistics: null,
      achievements: null,
      colval: "col-xl-4 col-lg-6 col-md-6 col-sm-12",
      sortedTestimonials: [],
      your_name: "",
      name: "",
      email: "",
      phone: "",
      errors: {},
      isSubmitting: false,
      standardOptions: {
        value: null,
        options: [],
        placeholder: "Select Standard",
        canDeselect: false,
        loading: true,
        searchable: true,
      },
      blogLoading: true,
      featuredBlogs: [],
      blogCount: null,
    };
  },

  created() {
    this.fetchContent();
    this.fetchBanners();
    this.loadActiveStandards();
    this.loadFeaturedEvents();
    this.fetchStudentStatistics();
    this.fetchAchievements();
    this.fetchTestimonials();
    this.loadStandards();
    this.fetchFeaturedBlogs();
  },
  computed: {
    isComingSoon() {
      let isComingSoon = JSON.parse(process.env.VUE_APP_COMING_SOON);
      return isComingSoon;
    },
  },
  methods: {
    fetchContent() {
      let params = {
        slug: "home",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadActiveStandards() {
      let params = {
        standard_with_programs: true,
      };
      await CommonService.getActiveStandards(params)
        .then((response) => {
          this.standardLoader = false;
          // console.log(response.data);

          // To convert objects of objects to array of objects
          let data = response.data.standards;
          let standards = Object.keys(data).map((key) => data[key]);

          if (response.data.status === "SUCCESS") {
            this.activeStandards = standards;
            if (this.activeStandards.length > 0) {
              let params = {
                standard_id: this.activeStandards[0].id,
              };
              this.standardId = this.activeStandards[0].id;
              this.standardText = this.activeStandards[0].standard_title;
              this.loadFeaturedPrograms(params);
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.activeStandards);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadFeaturedPrograms(data) {
      this.programLoader = true;
      this.featuredPrograms = [];
      await CourseService.getFeaturedPrograms(data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.programLoader = false;
            this.featuredPrograms = response.data.featured_programs;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.featuredPrograms);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadFeaturedEvents() {
      await EventService.getFeaturedEvents()
        .then((response) => {
          this.eventLoader = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.featuredEvents = response.data.featured_events;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.featuredEvents);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchSelectedStandardPrograms(selectedStandard) {
      let params = {
        standard_id: selectedStandard.id,
      };
      this.standardId = selectedStandard.id;
      this.standardText = selectedStandard.standard_title;
      this.loadFeaturedPrograms(params);
    },
    fetchBanners() {
      CommonService.getHomeBanners()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.banner = response.data.banner;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchStudentStatistics() {
      CommonService.getStudentStatistics()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.studentStatistics = response.data.statistics;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchAchievements() {
      CommonService.getAchievements()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.achievements = response.data.achievements;
            this.achievementsCount = this.achievements.length;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadStandards() {
      CommonService.getFeaturedStandards()
        .then((response) => {
          this.standardOptions.loading = false;
          if (response.data.status === "SUCCESS") {
            this.standardOptions.options = []; // to clear previously loaded options
            let standards = response.data.standards;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.standard_title,
                };
                this.standardOptions.options.push(options);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveCounselling() {
      const params = {
        your_name: this.your_name,
        name: this.name,
        email: this.email,
        phone_number: this.phone,
        standard_id: this.standardOptions.value,
        type: "HOME",
        home_page_enquiry: true, //only for home page enquiry form
      };
      this.isSubmitting = true;
      CommonService.saveCounselling(params)
        .then((response) => {
          this.isSubmitting = false;
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.standardOptions.value = null;
            this.your_name = "";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.errors = {};
          }
          if (response.data.status === "ERROR") {
            this.errors = response.data.message || {};
          }
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.error(error);
        });
    },
    async fetchFeaturedBlogs() {
      let params = {
        take: "4",
      };
      await CommonService.getInnerBlogs(params)
        .then((response) => {
          // console.log(response.data.innerBlogs);
          this.blogLoading = false;
          if (response.data.status == "SUCCESS") {
            this.featuredBlogs = response.data.innerBlogs;
            this.blogCount = response.data.blogCount;
          } else if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "@/style/home.scss";

.blog-card {
  text-align: left;
  text-decoration: unset;
  color: unset;
  display: block;
  margin-bottom: 30px;
  .img-wrap img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
  }
  .blog-body {
    min-height: 80px;
    margin-bottom: 20px;
    h5 {
      word-break: break-word;
      margin-top: 20px;
      color: #000;
      display: inline-block;
      padding-bottom: 3px;
      font-style: normal;
    }
    .info-row p {
      font-size: 13px;
      color: grey;
    }
  }
}
</style>
