import Http from "@/services/Http";

export default {
  getPrograms(data) {
    return Http().post("programs/all", data);
  },
  getFeaturedPrograms(data) {
    return Http().post("programs/all/featured", data);
  },
  getProgramData(data) {
    return Http().post("program/view", data);
  },
  initiateCheckout(data) {
    return Http().post("program/subscription/checkout", data);
  },
  applyPromoCode(data) {
    return Http().post("program/subscription/promocode/apply", data);
  },
  initiatePayment(data) {
    return Http().post("program/subscription/payment", data);
  },
  activateSubscription(data) {
    return Http().post("program/subscription/payment/verify", data);
  },
  getSubscribedProgramData(data) {
    return Http().post("subscribed/program/view", data);
  },
  getStudyMaterials(data) {
    return Http().post("study-materials", data);
  },
  downloadFile(data) {
    return Http().get("study-material/download?file=" + data, {
      responseType: "arraybuffer",
    });
  },
  getRecordings(data) {
    return Http().post("recordings", data);
  },
  getRecordingVideo(data) {
    return Http().post("recording/video", data);
  },
};
