import store from "@/store";

export default function unauthenticated({ next, router }) {
  // if logged in, then redirect
  if (store.getters["auth/isLoggedIn"] == true) {
    return router.push({
      name: "Dashboard",
    });
  }
  return next();
}
