<template>
  <div class="primary-header">
    <h3>What<span class="highlite"> Bodhians </span>say</h3>
  </div>
  <p class="subtag">
    <!-- Voluptatum porro dolore debitis, beatae dolor voluptates vel possimus
    asperiores accusamus ipsum nemo veniam illum doloribus mollitia blanditiis
    eius dolor accusantium fugit. -->
  </p>
  <div
    id="carouselTestimonialsControls"
    class="carousel slide carousel-fade"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        v-for="(testimonials, testimonialIndex) in sortedTestimonials"
        :key="testimonialIndex"
        class="carousel-item"
        :class="testimonialIndex === 0 ? 'active' : ''"
      >
        <div class="row p-4">
          <div
            class="col-md-6"
            v-for="testimonial in testimonials"
            :key="testimonial.id"
          >
            <div class="experience-card">
              <div class="_head">
                <div class="thumbanil">
                  <img
                    v-if="testimonial.image != null"
                    :src="testimonial.image"
                    alt=""
                  />
                  <img v-else src="../assets/thumb.png" alt="" />
                </div>
                <div>
                  <h6>{{ testimonial.name }}</h6>
                  <span>{{ testimonial.designation }}</span>
                </div>
              </div>
              <p>
                {{ testimonial.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselTestimonialsControls"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      id="nextTestimonialButton"
      data-bs-target="#carouselTestimonialsControls"
      data-bs-slide="next"
      ref="nextButton"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Testimonial",
  props: ["sortedTestimonials"],
  mounted() {
    this.$nextTick(() => {
      setInterval(() => {
        // Note:- Use either one method
        // # Method 1:-
        // let nextButton = document.querySelector("#nextTestimonialButton");
        // if (nextButton !== null) {
        //   nextButton.click();
        // }
        // #Method 2:-
        if (this.$refs.nextButton !== null) {
          this.$refs.nextButton.click();
        }
      }, 5000);
    });
  },
};
</script>
