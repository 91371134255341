<template>
  <div class="slide-wrapper">
    <div class="container">
      <div class="primary-header">
        <h3>Welcome to the Platform of</h3>
        <h3 class="highlite">High Achievers & Peak Performers</h3>
      </div>
      <div>
        <Carousel
          :autoplay="autoplayValue"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in achievements" :key="item.id">
            <div class="carousel__item">
              <div class="slide-card">
                <div class="_user-img">
                  <img
                    v-if="item.image != null"
                    :src="item.image"
                    class="d-block w-100"
                  />
                  <img v-else src="../assets/thumb.png" class="d-block w-100" />
                </div>
                <div class="_info">
                  <p class="name">{{ item.name }}</p>
                  <h3 class="main-head">{{ item.course }}</h3>
                  <h3 class="sub-head">{{ item.achievement }}</h3>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "Achievement",
  components: { Carousel, Slide, Pagination },
  props: ["achievements"],
  data() {
    return {
      autoplayValue: 0, // important settings - to prevent the incursive error if no data to show
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        transition: 800,
        wrapAround: true,
      },
      // breakpoints are mobile first
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  created() {
    this.calculateAchievementsCount();
  },
  methods: {
    calculateAchievementsCount() {
      if (this.achievements.length > 0) {
        this.autoplayValue = 2000;
      }
    },
  },
};
</script>
