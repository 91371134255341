import Http from "@/services/Http";

export default {
  login(data) {
    return Http().post("login", data);
  },
  requestLoginOTP(data) {
    return Http().post("login/request/otp", data);
  },
  loginWithOTPVerification(data) {
    return Http().post("login/request/otp/verify", data);
  },
  register(data) {
    return Http().post("register", data);
  },
  verifyRegisterOTP(data) {
    return Http().post("verify/user", data);
  },
  requestForgotPasswordOTP(data) {
    return Http().post("password/forgot", data);
  },
  resetPassword(data) {
    return Http().post("password/reset", data);
  },
  logout() {
    return Http().post("logout");
  },
  logoutAll(data) {
    return Http().post("logout/all", data);
  },
  getUnreadNotificationsCount() {
    return Http().post("get/count/unread/notifications");
  },
  getAllNotifications() {
    return Http().post("get/all/notifications");
  },
};
