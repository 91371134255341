<template>
  <div class="layout">
    <!-- ommited -->
    <VueSocialChat icon :attendants="attendants">
      <template #header>
        <p>Technical support - {{ company.name }}</p>
      </template>
      <template #button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <template #footer>
        <small>Operational hours - {{ operationalHours }}</small>
      </template>
    </VueSocialChat>
  </div>
</template>

<script>
export default {
  name: "WhatsappChat",
  props: ["company"],
  data() {
    return {
      operationalHours: process.env.VUE_APP_WHATSAPP_OPERATIONAL_HOURS,
    };
  },
  setup(props) {
    const attendants = [
      {
        app: "whatsapp",
        label: "Technical support",
        name: props.company.name,
        number: process.env.VUE_APP_WHATSAPP_NUMBER,
        avatar: {
          src: props.company.logo,
          alt: "VBLive Logo",
        },
      },
    ];
    return { attendants };
  },
};
</script>
// Style is written on the footer component
