<template>
  <div class="dropdown">
    <a
      @click="getAllNotifications()"
      v-if="this.$store.getters['auth/isLoggedIn'] == true"
      href="javascript:void(0);"
      class="btn cta-icon"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img src="../assets/icon-bell.png" alt="" />
      <span v-if="feedbackNotificationCount > 0" class="count">
        {{ feedbackNotificationCount }}
      </span>
    </a>
    <ul
      v-if="notifications && feedbackNotificationCount > 0"
      class="dropdown-menu dropdown-menu-end"
    >
      <li v-for="notification in notifications" :key="notification.id">
        <a
          class="dropdown-item"
          href="javascript:void(0);"
          @click="markNotificationAsRead(notification)"
        >
          <p>
            {{ notification.data.text }}
          </p>
          <p>
            {{ notificationDateFormatted(notification.created_at) }}
          </p>
        </a>
      </li>
    </ul>
    <ul v-else class="dropdown-menu dropdown-menu-end">
      <li>
        <a class="dropdown-item" href="javascript:void(0);">
          No notifications
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import DashboardService from "@/services/DashboardService";
import moment from "moment";
export default {
  name: "PushNotification",
  data() {
    return {
      feedbackNotificationCount: 0,
      notifications: [],
    };
  },
  created() {
    this.UnreadNotificationsCount();
  },
  methods: {
    async UnreadNotificationsCount() {
      await AuthService.getUnreadNotificationsCount().then((output) => {
        if (output.data.status === "SUCCESS") {
          this.feedbackNotificationCount = output.data.notification_count;
        }
      });
    },
    async getAllNotifications() {
      await AuthService.getAllNotifications()
        .then((output) => {
          if (output.data.status == "SUCCESS") {
            this.notifications = output.data.notifications;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    notificationDateFormatted(date) {
      let convertedDateFormatted = moment(date).format("MMM D, YYYY");
      return convertedDateFormatted;
    },
    async markNotificationAsRead(notification) {
      let params = {
        notification_id: notification.id,
      };
      if (notification.read_at == null) {
        await DashboardService.readNotification(params)
          .then((result) => {
            if (result.data.status === "SUCCESS") {
              this.UnreadNotificationsCount();
              this.getAllNotifications();
              this.$router.push({
                name: "MyFeedback",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>
