import store from "@/store";
export default function auth({ next, router, to }) {
  if (store.getters["auth/isLoggedIn"] != true) {
    store.dispatch("auth/updateNextUrl", to.path);
    return router.push({
      name: "Login",
    });
  } else {
    // let user = JSON.parse(store.getters["auth/user"]);
    // if (user.email_verified_at == null) {
    //   return router.push({ name: "emailVerification" });
    // }
  }
  return next();
}
