<template>
  <div class="col-md-3">
    <div class="event-card">
      <div class="_card-img">
        <img
          v-if="event.image != null"
          :src="event.image"
          class="card-img-top"
          alt="..."
        />
        <img v-else src="../assets/event-thumb.png" alt="" />
      </div>
      <div class="_body">
        <span class="tag">Event</span>
        <span class="tag">{{ event.type }}</span>
        <div class="_head">
          <h5>{{ event.title }}</h5>
          <div v-if="event.price.final_price > 0" class="price">
            <h5>₹ {{ event.price.final_price }}</h5>
          </div>
        </div>
        <p class="date">{{ convertedTimeForUser }}</p>
      </div>
      <div class="_lead">
        <router-link
          :to="{
            name: 'EventDetails',
            params: { event_id: event.id, slug: event.slug },
          }"
        >
          <a class="nav-cta">
            <i class="fa fa-chevron-right"></i>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from "@/services/Timezone";
export default {
  name: "EventCard",
  components: {},
  props: ["event"],
  mounted() {
    // console.log(this.$store.getters['auth/user']);
  },
  computed: {
    convertedTimeForUser() {
      return Timezone.convertedTimeZone(this.event.start_time).format(
        "Do MMMM"
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/style/event-card.scss";
</style>
