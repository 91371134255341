import Http from "@/services/Http";

export default {
  getActiveBoards(data) {
    return Http().post("filter/active/boards", data);
  },
  getActiveStandards(data) {
    return Http().post("filter/active/standards", data);
  },
  getActiveSchools() {
    return Http().post("filter/active/schools");
  },
  getAllCountries() {
    return Http().post("countries/all");
  },
  getAllTimezones() {
    return Http().post("timezones/all");
  },
  getContentPage(data) {
    return Http().post("content-page", data);
  },
  getTestimonials() {
    return Http().post("testimonials");
  },
  getFAQs() {
    return Http().post("faqs");
  },
  getHomeBanners() {
    return Http().post("home/banners");
  },
  getStudentStatistics() {
    return Http().post("home/student/statistics");
  },
  getActiveSubscribedPrograms() {
    return Http().post("subscribed/active/programs");
  },
  getActiveSubscribedProgramTeachers(data) {
    return Http().post("subscribed/active/program/teachers", data);
  },
  getActiveSubscribedProgramSubjects(data) {
    return Http().post("subscribed/active/program/subjects", data);
  },
  saveCounselling(data) {
    return Http().post("counselling/save", data);
  },
  getCompanyProfile() {
    return Http().post("company/profile");
  },
  getAchievements() {
    return Http().post("home/achievements");
  },
  getActiveFeedbackCategories() {
    return Http().post("feedback/active/categories");
  },
  getAllPrograms() {
    return Http().post("get/all/programs");
  },
  getFeaturedStandards(data) {
    return Http().post("featured/standards", data);
  },
  getLatestFirstBlog() {
    return Http().post("blog/latest/first");
  },
  getBlogs(data) {
    return Http().post("blog/all", data);
  },
  getBlogDetails(data) {
    return Http().post("blog/view", data);
  },
  getInnerBlogs(data) {
    return Http().post("blog/inner", data);
  },
};
