import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import unauthenticated from "../middleware/unauthenticated";
import auth from "../middleware/auth";
import store from "@/store";
import moment from "moment";
import AuthService from "@/services/AuthService";

const routes = [
  {
    // path: "/:catchAll(.*)", // to handle 404 - Not found pages » This also works
    path: "/:pathMatch(.*)*", // to handle 404 - Not found pages » from vue-router v4.x
    alias: "/404",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/meeting/:id",
    name: "CourseMeeting",
    component: () => import("../views/Meeting.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsAndConditions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      middleware: [unauthenticated],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      middleware: [unauthenticated],
      title: "Register | VBLive - Your Online Tutor",
    },
  },
  // {
  //   path: "/explore",
  //   name: "ExploreAll",
  //   component: () => import("../views/Explore.vue"),
  // },
  {
    path: "/enquire",
    name: "CareerCounselling",
    component: () => import("../views/CareerCounselling.vue"),
  },
  {
    path: "/courses",
    name: "ExploreCourses",
    component: () => import("../views/Courses.vue"),
  },
  {
    path: "/events",
    name: "ExploreEvents",
    component: () => import("../views/EventListing.vue"),
    meta: {
      title: "Events | VBLive - Your Online Tutor",
    },
  },
  {
    path: "/category/:slug",
    name: "CategoryListing",
    component: () => import("../views/CategoryListing.vue"),
  },
  {
    path: "/blog",
    name: "BlogListing",
    component: () => import("../views/BlogListing.vue"),
  },
  {
    path: "/blog/:slug",
    name: "BlogSingle",
    component: () => import("../views/BlogSingle.vue"),
  },
  {
    path: "/course/:slug",
    // name: "CourseInfo",
    component: () => import("../views/CourseInfo.vue"),
    children: [
      {
        path: "",
        name: "CourseDetails",
        component: () => import("../views/CourseDetail.vue"),
      },
      {
        path: "checkout/:program_id",
        name: "CourseCheckout",
        component: () => import("../views/Checkout.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment/success",
        name: "CoursePaymentSuccess",
        component: () => import("../views/PaymentSuccess.vue"),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/event/:slug",
    // name: "EventInfo",
    component: () => import("../views/EventInfo.vue"),
    children: [
      {
        path: "",
        name: "EventDetails",
        component: () => import("../views/EventDetail.vue"),
      },
      {
        path: "checkout/:event_id",
        name: "EventCheckout",
        component: () => import("../views/Checkout.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment/success",
        name: "EventPaymentSuccess",
        component: () => import("../views/PaymentSuccess.vue"),
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/dashboard",
    // name: "MyDashboard",
    component: () => import("../views/StudentDashboard.vue"),
    meta: {
      middleware: [auth],
      title: "Dashboard | VBLive - Your Online Tutor",
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("../components/DashboardTab.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../components/ProfileTab.vue"),
      },
      {
        path: "my-courses",
        name: "MyCourses",
        component: () => import("../components/CourseTab.vue"),
      },
      {
        path: "my-courses/details/:id",
        name: "MyCourseDetails",
        component: () => import("../components/ProgramDetails.vue"),
      },
      {
        path: "my-events",
        name: "MyEvents",
        component: () => import("../components/EventTab.vue"),
      },
      {
        path: "my-academics",
        name: "MyAcademics",
        component: () => import("../components/AcademicTab.vue"),
      },
      {
        path: "/my-discussion-board",
        name: "MyDiscussionBoard",
        component: () => import("../views/DiscussionTab.vue"),
      },
      {
        path: "/my-feedback",
        name: "MyFeedback",
        component: () => import("../views/FeedbackTab.vue"),
      },
      {
        path: "/recordings/:id",
        name: "recordings",
        component: () => import("../components/RecordingsTab.vue"),
      },
      {
        path: "/recording/video/:id",
        name: "recordingVideo",
        component: () => import("../components/RecordingVideoTab.vue"),
      },
    ],
  },
  {
    // path: "/subscribed/course/:program_id/:slug",
    path: "/subscribed/course/:program_id",
    // name: "SubscribedCourse",
    component: () => import("../views/SubscribedCourse.vue"),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "",
        name: "SubscribedCourseActivities",
        component: () => import("../components/SubscribedCourseActivity.vue"),
      },
      {
        path: "assignment/:assignment_id",
        name: "CourseAssignment",
        component: () => import("../views/Assignment.vue"),
      },
      {
        path: "exam/instructions/:exam_id",
        name: "CourseExamInstructions",
        component: () => import("../views/CourseExamInstructions.vue"),
      },
      {
        path: "exam/:exam_id",
        name: "CourseExam",
        component: () => import("../views/CourseExam.vue"),
      },
    ],
  },
  {
    path: "/subscribed/course/:program_id/discussion-board/:discussion_id",
    name: "DiscussionBoard",
    component: () => import("../views/DiscussionBoard.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/feedback/:feedback_id/view",
    name: "FeedbackView",
    component: () => import("../views/FeedbackView.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/careers",
    name: "VBCareer",
    component: () => import("../views/Career.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  /* Note:- to update the title tag with default value if none provided through route or Vue Head component inside the view template
    However, the default title will be overwritten by the Vue Head component if mentioned */
  document.title = to.meta.title || "VBLive - Your Online Tutor";
  const user = store.getters["auth/user"];
  if (user.length != undefined) {
    const expirationTime = store.getters["auth/expirationTime"];
    if (expirationTime != "") {
      var expirationTimeInUTC = moment.utc(expirationTime);
      var currentTimeInUTC = moment.utc();
      // Note: to use .diff(), do not format the moment value.
      // console.log(currentTimeInUTC.diff(expirationTimeInUTC));
      if (currentTimeInUTC.diff(expirationTimeInUTC) > 0) {
        AuthService.logout().then((result) => {
          store.dispatch("auth/logout");
          console.log(result.data);
          router.push({
            name: "Login",
          });
        });
      } else {
        const timeInUTC = moment.utc().add(5, "hours").format();
        store.dispatch("auth/SetExpirationTime", timeInUTC);
      }
    } else {
      const timeInUTC = moment.utc().add(5, "hours").format();
      store.dispatch("auth/SetExpirationTime", timeInUTC);
    }
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1, to);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

function nextFactory(context, middleware, index, to) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware, to });
  };
}

export default router;
