<template>
  <img
    src="../../assets/circularLoader.svg"
    class="circular-loader mx-auto d-block"
  />
</template>
<script>
export default {
  name: "CircularLoader",
  components: {},
};
</script>
