<template>
  <Navbar v-if="currentRouteName != 'CourseMeeting'" :company="company" />
  <!-- <router-view /> -->
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" :company="company" />
    </transition>
  </router-view>

  <Footer v-if="currentRouteName != 'CourseMeeting'" :company="company" />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CommonService from "@/services/CommonService";

export default {
  name: "app",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      company: null,
    };
  },
  created() {
    if (this.currentRouteName != "CourseMeeting") {
      this.fetchCompanyProfile();
    }
  },
  methods: {
    fetchCompanyProfile() {
      CommonService.getCompanyProfile()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.company = response.data.company;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="css">
/* For Fade transistion style when router changes [starts] */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* For Fade transistion style when router changes [ends] */
</style>
