import store from "@/store";
import moment from "moment-timezone";

export default {
  convertedTimeZone(date) {
    /* set default timezone */
    let defaultTimezone = process.env.VUE_APP_STUDENT_TIMEZONE;

    /* if user is logged in */
    let user = store.state.auth.user;
    // console.log(user);
    // console.log(user.length);
    if (user.length != undefined) {
      user = JSON.parse(user);

      // check if the key exists
      if (typeof user["timezone"] !== "undefined") {
        // console.log("exists");
        if (user.timezone != null) {
          defaultTimezone = user.timezone;
        }
      }
    } else {
      /* If user is not logged in & not in the default timezone */
      // TODO: Get the user timezone from browser if not logged in (Scenario:- If the user is not from the default timezone)
      // let guessedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Also works
      let guessedTimezone = moment.tz.guess(true);
      // console.log('Moment Guessed your location as: ', guessedTimezone);

      if (defaultTimezone !== guessedTimezone) {
        defaultTimezone = guessedTimezone;
      }
    }

    // Courtesy:- https://momentjs.com/timezone/
    const timeInUTC = moment.utc(date);
    const userTimezone = timeInUTC.clone().tz(defaultTimezone);

    return userTimezone;
  },
};
