<template>
  <div :class="colValue">
    <div class="course-card">
      <div v-if="toggleImage" class="">
        <!-- class="_img-wrap" -->
        <router-link
          :to="{ name: redirectRoute.name, params: redirectRoute.params }"
        >
          <img
            v-if="program.image != null"
            :src="program.image"
            class="card-img-top"
            alt="..."
          />
          <img
            v-else
            src="../assets/module-illus.png"
            class="card-img-top"
            alt="..."
          />
        </router-link>
      </div>
      <div class="_body">
        <div class="_head">
          <ul class="list-unstyled sub-list">
            <li
              v-for="program_subject in programSubjects"
              :key="program_subject.subject_id"
            >
              {{ program_subject.subject.subject_title }}
            </li>
          </ul>
          <div class="height-content">
            <router-link
              :to="{ name: redirectRoute.name, params: redirectRoute.params }"
            >
              <h4>{{ program.program_title }}</h4></router-link
            >
            <p
              v-if="
                $route.name == 'MyCourses' && program.subscription_status == 0
              "
            >
              <span>You are no Longer subscribed to this course!.</span>
            </p>
            <p>{{ program.standard.standard_title }}</p>
            <p>{{ program.board.board_title }}</p>
            <ul class="list-unstyled schedule-list" v-if="toggleFilteredWeeks">
              <li v-for="(week, index) in filteredWeeks" :key="index">
                {{ week }}
                <span v-if="index != filteredWeeks.length - 1"> | </span>
              </li>
              <!-- To correct the height of the card if no schedule -->
              <!-- <li><br /></li> -->
            </ul>
          </div>
          <div class="price">
            <h4 v-if="togglePrice">
              <del v-if="program.price.off_amount > 0"
                >₹ {{ program.price.price }}</del
              >
              ₹ {{ program.price.final_price }}
            </h4>
            <!-- <span>per student</span> -->
          </div>
        </div>
        <div v-if="toggleBanner" class="flyer-img">
          <img
            v-if="program.image != null"
            :src="program.image"
            class="card-img-top"
            alt="..."
          />
          <img
            v-else
            src="../assets/Course-single-img.png"
            class="card-img-top"
          />
        </div>
        <div class="_lead">
          <router-link
            class="nav-cta"
            :to="{ name: redirectRoute.name, params: redirectRoute.params }"
          >
            <i class="fa fa-chevron-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from "@/services/Timezone";
export default {
  name: "CourseCard",
  components: {},
  props: [
    "program",
    "toggleImage",
    "toggleBanner",
    "togglePrice",
    "toggleFilteredWeeks",
    "routeLink",
    "colValue",
  ],
  data() {
    return {
      weeks: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      programSubjects: [],
      redirectRoute: {
        name: "",
        params: {},
      },
    };
  },
  mounted() {
    this.populateProgramSubjects();
    this.switchRoute();
  },
  computed: {
    filteredWeeks() {
      let schedules = this.program.schedules;
      let scheduledWeeks = [];
      let filteredScheduledWeeks = [];
      for (let i = 0; i < schedules.length; i++) {
        scheduledWeeks.push(
          Timezone.convertedTimeZone(schedules[i].start_time).format("dddd")
        );
      }
      filteredScheduledWeeks = this.weeks.filter((week) => {
        return scheduledWeeks.includes(week);
      });
      return filteredScheduledWeeks;
    },
  },
  methods: {
    switchRoute() {
      let routeName = this.$route.name;
      if (routeName === "MyCourses") {
        this.redirectRoute = {
          name: "MyCourseDetails",
          params: {
            id: this.program.id,
          },
        };
      } else {
        this.redirectRoute = "";
        this.redirectRoute = {
          name: "CourseDetails",
          params: {
            // program_id: this.program.id,
            slug: this.program.slug,
          },
        };
      }
      return this.redirectRoute;
    },
    populateProgramSubjects() {
      this.programSubjects = this.program.program_subjects.slice(0, 3);
      let extraSubjects = {
        subject_id: -1,
        subject: { subject_title: "..." },
      };
      if (this.program.program_subjects.length > 3) {
        this.programSubjects.push(extraSubjects);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/course-card.scss";
</style>
