<template>
  <div id="nav">
    <div class="container-fluid nav-wrapper" :class="navbarClass">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <router-link
            aria-current="page"
            :to="{ name: homeRoute }"
            class="navbar-brand"
          >
            <img
              v-if="company && company.logo != null"
              :src="company.logo"
              alt="VBLive"
            />
            <!-- <img v-else src="../assets/logo.svg" alt="VBLive" /> -->
          </router-link>
          <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
          <div
            v-if="!isComingSoon"
            class="collapse navbar-collapse"
            id="navbarText"
          >
            <ul v-if="isActiveNavbar" class="navbar-nav mb-2 ms-3 mb-lg-0">
              <!-- <li class="nav-item">
                <router-link
                  class="nav-link active"
                  :to="{ name: 'ExploreAll' }"
                  >Explore</router-link
                >
              </li> -->
              <li class="nav-item">
                <router-link
                  class="nav-link active"
                  :to="{ name: 'ExploreCourses' }"
                  >Courses</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link active"
                  :to="{ name: 'ExploreEvents' }"
                  >Events</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'About' }"
                  >About</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  v-if="this.$store.getters['auth/isLoggedIn'] == true"
                  :to="{ name: 'Dashboard' }"
                  >Dashboard</router-link
                >
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/about"
                  >Our Methods</router-link
                >
              </li> -->
            </ul>
          </div>
          <router-link
            v-if="
              this.$store.getters['auth/isLoggedIn'] != true && !isComingSoon
            "
            :to="{ name: 'Login' }"
          >
            <a class="btn cta-primary"> Login </a>
          </router-link>
          <a
            class="btn cta-primary"
            v-if="
              this.$store.getters['auth/isLoggedIn'] == true && !isComingSoon
            "
            @click="logout"
          >
            Logout
          </a>
          <PushNotification
            v-if="this.$store.getters['auth/isLoggedIn'] == true"
          />
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import PushNotification from "@/components/PushNotification.vue";
export default {
  name: "Navbar",
  components: {
    PushNotification,
  },
  props: ["company"],
  data() {
    return {
      scrollPosition: null,
      navbarClass: "",
      homeRoute: "Home",
    };
  },
  computed: {
    user() {
      let user = this.$store.state.auth.user;
      if (user.length == undefined) {
        this.logout();
        user = {};
      } else {
        user = JSON.parse(user);
      }
      return user;
    },
    isComingSoon() {
      let isComingSoon = JSON.parse(process.env.VUE_APP_COMING_SOON);
      return isComingSoon;
    },
    isActiveNavbar() {
      let activeNavbar = true;
      let routeName = this.$route.name;
      if (routeName === "CourseExam") {
        activeNavbar = false;
        this.disableHomeRoute();
      } else {
        this.activateHomeRoute();
      }
      return activeNavbar;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.navbarClass = this.scrollPosition == 0 ? "" : "fixed";
    },
    disableHomeRoute() {
      this.homeRoute = "";
    },
    activateHomeRoute() {
      this.homeRoute = "Home";
    },
    logout() {
      AuthService.logout()
        .then((response) => {
          this.$store.dispatch("auth/logout");
          this.$toast.success(response.data.message);
          this.$router.push({
            name: "Home",
          });
        })
        .catch((error) => {
          this.error = error;
          this.$store.dispatch("auth/logout");
          this.$router.push({
            name: "Home",
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/navbar.scss";
</style>
