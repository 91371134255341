<template>
  <footer class="footer-class">
    <div class="container-fluid footer-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <a class="footer-brand" href="javascript:void(0);">
              <img
                v-if="company && company.footer_logo != null"
                :src="company.footer_logo"
                alt=""
              />
              <img v-else src="../assets/vb-white.png" alt="" />
            </a>
            <ul v-if="company" class="list-unstyled links-wrap">
              <li
                v-if="
                  company.primary_contact_number != null ||
                  company.email != null ||
                  company.facebook_link != null ||
                  company.instagram_link != null ||
                  company.linkedin_link != null ||
                  company.twitter_link != null ||
                  company.youtube_link != null
                "
              >
                connect to us
              </li>
              <li v-if="company.primary_contact_number != null">
                <a :href="'tel:' + company.primary_contact_number">{{
                  company.primary_contact_number
                }}</a>
              </li>
              <li v-if="company.email != null">
                <a :href="'mailto:' + company.email">{{ company.email }}</a>
              </li>
            </ul>
            <ul v-if="company" class="list-unstyled social-div links-wrap">
              <li v-show="company.facebook_link">
                <a @click="redirectLink(company.facebook_link)"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li v-show="company.instagram_link">
                <a @click="redirectLink(company.instagram_link)"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li v-show="company.linkedin_link">
                <a @click="redirectLink(company.linkedin_link)"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </li>
              <li v-show="company.twitter_link">
                <a @click="redirectLink(company.twitter_link)"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li v-show="company.youtube_link">
                <a @click="redirectLink(company.youtube_link)"
                  ><i class="fab fa-youtube"></i
                ></a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul v-if="allPrograms" class="list-unstyled links-wrap">
              <li>Courses</li>
              <li v-for="allProgram in allPrograms" :key="allProgram.id">
                <router-link
                  :to="{
                    name: 'CourseDetails',
                    params: { slug: allProgram.slug },
                  }"
                >
                  {{ allProgram.program_title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul v-if="allStandards" class="list-unstyled links-wrap">
              <li>Classes</li>
              <li v-for="allStandard in allStandards" :key="allStandard.id">
                <router-link
                  :to="{
                    name: 'ExploreCourses',
                    query: { standard: allStandard.standard_title },
                  }"
                >
                  {{ allStandard.standard_title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul v-if="isActiveFooter" class="list-unstyled links-wrap">
              <li>Other Links</li>
              <li>
                <router-link :to="{ name: 'About' }">About</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'TermsAndConditions' }"
                  >Terms & Conditions</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'PrivacyPolicy' }"
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'BlogListing' }">Blog</router-link>
              </li>
            </ul>
            <div class="download-div">
              <!-- <span> Download app </span> -->
              <a
                href="https://play.google.com/store/apps/details?id=com.vblive.vblive_student"
                target="_blank"
              >
                <img src="../assets/Google-Play.png" class="store-icon" />
              </a>
              <a
                href="https://apps.apple.com/in/app/vblive-your-online-tutor/id1591906505"
                target="_blank"
              >
                <img src="../assets/App-Store.png" class="store-icon" />
              </a>
            </div>
          </div>
        </div>
        <!-- <ul class="payment-div">
          <li>
            <p class="py-2">We accepts</p>
          </li>
          <li>
            <img src="../assets/razorpay-logo.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-1.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-2.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-3.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-4.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-5.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-6.png" alt="" />
          </li>
          <li>
            <img src="../assets/PAY-7.png" alt="" />
          </li>
        </ul> -->
        <div class="para-row">
          <p v-if="footer_content" v-html="footer_content.footer_content"></p>
        </div>
      </div>
    </div>
    <div class="container-fluid sub-footer">
      <p v-if="company" class="text-center">
        Copyright @ {{ company.name }} {{ currentYear }} all right reserved
      </p>
    </div>
    <WhatsappChat v-if="company" :company="company" />
  </footer>
</template>

<script>
import moment from "moment";
import WhatsappChat from "@/components/WhatsappChat.vue";
import CommonService from "@/services/CommonService";

export default {
  name: "Footer",
  components: {
    WhatsappChat,
  },
  props: {
    company: {
      default: null,
    },
  },
  data() {
    return {
      allPrograms: [],
      allStandards: [],
      footer_content: null,
    };
  },

  computed: {
    currentYear() {
      return moment(new Date()).format("YYYY");
    },
    isActiveFooter() {
      let activeFooter = true;
      let routeName = this.$route.name;
      if (routeName === "CourseExam") {
        activeFooter = false;
      }
      return activeFooter;
    },
  },
  created() {
    this.loadAllPrograms();
    this.loadFeaturedStandards();
    this.loadFooterContent();
    // this.loadCourseCategories();
  },
  methods: {
    async loadAllPrograms() {
      this.allPrograms = [];
      await CommonService.getAllPrograms()
        .then((response) => {
          // console.log(response.data.programs);
          if (response.data.status === "SUCCESS") {
            this.allPrograms = response.data.programs;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.featuredPrograms);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadFeaturedStandards() {
      let params = {
        type: "footer",
      };
      this.allStandards = [];
      await CommonService.getFeaturedStandards(params)
        .then((response) => {
          // console.log(response.data.programs);
          if (response.data.status === "SUCCESS") {
            this.allStandards = response.data.standards;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.featuredPrograms);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadFooterContent() {
      let params = {
        slug: "home",
      };
      await CommonService.getContentPage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.footer_content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    redirectLink(link) {
      // console.log(link);
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss">
.footer-class {
  padding-top: 4rem;
  background: #369f91;
  color: #fff;
  // clip-path: polygon(84% 12%, 100% 7%, 100% 100%, 0 100%, 0 0);
  .download-div {
    margin-top: 30px;
    a {
      margin: 10px;
      padding: 0;
      display: inline-block;
      &:hover {
        filter: drop-shadow(0px 2px 6px #00000057);
      }
    }
    img {
      width: 100px !important;
    }
  }
  .para-row {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.349);
    h6 {
      color: #ffffffad;
      font-weight: 600;
      font-size: 13px;
    }
    p {
      font-size: 13px;
    }
  }
  .payment-div {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 10px;
      img {
        width: 80px;
        height: 50px;
        object-fit: contain;
      }
      &:nth-child(1) {
        text-transform: capitalize;
        margin-bottom: 5px;
        color: #ffffffad;
        font-weight: 500;
      }
    }
  }
  // For WhatsApp Chat Popup [starts]
  .vsc-popup-button {
    padding: 10px;
    background-color: #25d366;
    &:hover {
      border-color: #25d366;
    }
  }
  .vsc-popup-button:focus,
  .vsc-popup-button:hover {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #46c056;
  }
  .vsc-popup-header {
    border-bottom: 5px solid #289d37;
    background-color: #46c056;
  }
  .vsc-popup-footer {
    background-color: #fafafa;
    color: #333;
  }

  .vsc-popup-body__link-avatar > img {
    object-fit: contain;
  }
  // For WhatsApp Chat Popup [ends]

  a.footer-brand {
    display: block;
    width: 140px;
    padding-bottom: 20px;
  }
  p a {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    &:hover {
      color: var(--accent);
    }
  }
  .links-wrap {
    margin-top: 2rem;
    li {
      font-size: 13px;
      &:nth-child(1) {
        text-transform: capitalize;
        margin-bottom: 5px;
        color: #ffffffad;
        font-weight: 800;
        font-size: 15px;
      }
      a {
        text-decoration: none;
        font-weight: 200;
        font-size: 15px;
        color: #fff;
        line-height: 18px;
        &:hover {
          color: var(--accent);
        }
        i {
          font-size: 10px;
          margin-right: 10px;
          color: #cdcdcd;
        }
      }
    }
    &.social-div {
      display: flex;
      a {
        background: #3f5855;
        // padding: 6px;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        display: grid;
        place-items: center;
        margin-right: 10px;
        &:hover i {
          color: var(--accent);
        }
      }
      i {
        font-size: 13px;
        margin-right: 0;
      }
    }
  }
}
.sub-footer {
  background: #000;
  p {
    margin-bottom: 0;
    padding: 10px 0;
    font-size: 13px;
    color: #f8f8f8, 100%;
  }
}
@media only screen and (max-width: 600px) {
  .footer-class {
    clip-path: polygon(84% 6%, 100% 7%, 100% 100%, 0 100%, 0 0);
  }
}
</style>
