import { createStore } from "vuex";
import Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";

const persistedStateOptions = {
  paths: ["auth"],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) =>
      Cookies.set(key, value, {
        expires: 1000000,
        secure: false,
      }),
    removeItem: (key) => Cookies.remove(key),
  },
};
const store = createStore({
  modules: {
    auth: auth,
  },
  plugins: [createPersistedState(persistedStateOptions)],
});

export default store;
