import Http from "@/services/Http";

export default {
  getProfile() {
    return Http().post("profile");
  },
  updateProfile(data) {
    return Http().post("profile/update", data);
  },
  updateProfileImage(data) {
    return Http().post("profile/image/update", data);
  },
  getSubscribedPrograms(data) {
    return Http().post("subscribed/programs/all", data);
  },
  getUpcomingSchedules(data) {
    return Http().post("subscribed/programs/upcoming/schedules/all", data);
  },
  getExpiredSchedules(data) {
    return Http().post("subscribed/programs/expired/schedules/all", data);
  },
  livePrograms() {
    return Http().post("program/live");
  },
  getAttendance() {
    return Http().post("attendance");
  },
  getSubscribedEvents(data) {
    return Http().post("subscribed/events/all", data);
  },
  getUnreadNotificationsCount() {
    return Http().post("notifications/unread/count");
  },
  getAllNotifications(data) {
    return Http().post("notifications/all", data);
  },
  readAllNotifications() {
    return Http().post("notifications/all/read");
  },
  readNotification(data) {
    return Http().post("notification/read", data);
  },
};
