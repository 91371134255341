import Http from "@/services/Http";

export default {
  getEvents(data) {
    return Http().post("events/all", data);
  },
  getFeaturedEvents(data) {
    return Http().post("events/all/featured", data);
  },
  getEventData(data) {
    return Http().post("event/view", data);
  },
  initiateCheckout(data) {
    return Http().post("event/subscription/checkout", data);
  },
  applyPromoCode(data) {
    return Http().post("event/subscription/promocode/apply", data);
  },
  initiatePayment(data) {
    return Http().post("event/subscription/payment", data);
  },
  activateSubscription(data) {
    return Http().post("event/subscription/payment/verify", data);
  },
};
