import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import "./style/style.scss";
import VCalendar from "v-calendar";
import VueScrollTo from "vue-scrollto";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const toastConfig = {
  position: "top",
  duration: 5000,
};

const scrollToConfig = {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
};

const loaderConfig = {
  color: "#34A092",
  loader: "dots",
  width: 70,
  height: 70,
  backgroundColor: "#ffffff",
  opacity: 0.5,
  zIndex: 99999,
};

const app = createApp(App);

app
  .use(router)
  .use(createHead())
  .use(store)
  .use(VueLoading, loaderConfig)
  .use(VueSocialChat)
  .use(Toaster, toastConfig)
  .use(VueSweetalert2)
  .use(VCalendar, {})
  .use(Vue3VideoPlayer, {
    lang: "en",
  })
  .use(VueScrollTo, scrollToConfig);

app.mount("#app");
